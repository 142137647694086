import React from "react"

import { Router } from "@reach/router"
import HomePage from "../components/Pages/HomePage/HomePage"
import PrivateRoute from "../components/Pages/PrivateRoute"

const NotFoundPage = () => (
 <Router>
    <PrivateRoute path="/app/home" component={HomePage} destination="/app/home" />
  </Router>
)

export default NotFoundPage
